import React ,{ useEffect,useState,useRef}from 'react'
import instance from "../../../../api/api";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Modal } from "antd";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {TabView,TabPanel} from "primereact/tabview";
import { Dialog } from "antd-mobile";
import { NavLink } from "react-router-dom";


function User() {
  const [utilisateur,setUtilisateur]=useState([]);
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await instance.get("getAllUser");
        // console.log("getAllUser",response);
        if (response.data.status) {
          setUtilisateur(response.data.data.filter(item=>item.role.libelle !== "Admin"));
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);
  return (
    <div>
        <UserDatatable items={utilisateur} />
    </div>
  )
}

function UserDatatable({ items }) {
    const dt = useRef(null);
  
    const footer = `${items ? items.length : 0} utilisateurs`;
    const desactiver = async (idCat) => {
    
        try {
          const response = await instance.delete("deleteCategorie/"+idCat);
          // console.log("deleteCategorie",response);
          if (response.data.status) {
            Dialog.alert({
                content:response.data.message ,
                 confirmText:"OK"
              });
           
          }
        } catch (error) {
          console.log(error);
        }
      };
      
    const Action = (rowData) => {
      return (
        <button 
        onClick={()=>{
          desactiver(rowData.id)
        }}
        className='btn bg-dark text-light'>
            Désactiver
            </button>
      );
    };
    const seeDetail = (rowData)=>{
        return (
            <NavLink to={"/detail/utilisateur/" + rowData.id} >
              Voir plus
            </NavLink>
           
          );
        };

    const getName=(rowData)=>{
      return(
        <p>{rowData.nom} {rowData.prenoms}</p>
      )
    }
    const getRole=(rowData)=>{
      return(
        <p>{rowData.role.libelle}</p>
      )
    }
    
  
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
  
      _filters["global"].value = value;
  
      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      const conteneurSearch = {
        padding: "1.3em",
      };

      return (
        <div className="conteneur-search">
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={conteneurSearch}
          />
        </div>
      );
    };
    const header = renderHeader();
  
    return (
      <div>
        
          <TabView>
            <TabPanel>
              <DataTable
                ref={dt}
                value={items}
                footer={footer}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={5}
                filters={filters}
                globalFilterFields={["libelle"]}
                header={header}
              >
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Nom & prénoms"
                  body={getName}
                ></Column>
                <Column
                  field="email"
                  style={{ textAlign: "center" }}
                  header="Email"
                ></Column>
                <Column
                  field="phone"
                  style={{ textAlign: "center" }}
                  header="Phone"
                ></Column>
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Statut"
                  body={getRole}
                ></Column>
  
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Action"
                  body={Action}
                ></Column>
                 <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Détail"
                  body={seeDetail}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
      </div>
    );
  }

export default User