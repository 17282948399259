import React, { useEffect, useState} from 'react'
import { useParams,NavLink } from "react-router-dom";
import instance from "../../../../api/api";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Dialog } from "antd-mobile";
import { Modal } from "antd";
function DetailCommune() {
    const {id,libelle}=useParams();
      const [detailVille,setDetailVille]=useState(null);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [isLoading,setIsLoading]=useState(false);
      const [isLoadingDelete,setIsLoadingDelete] =useState(false);
      const [data,setData]=useState({
        libelle:"",
      })
      const [dataCreate,setDataCreate]=useState({
        libelle:"",
        ville_id:id
      })
      const createCommune = async () => {
        try {
          const response = await instance.post("saveCommune",dataCreate);
          // console.log("createCategorie",response);
          if (response.data.status) {
            Dialog.alert({
                content:"success" ,
                 confirmText:"OK"
              });
          }
        } catch (error) {
          console.log(error);
          Dialog.alert({
            content:error,
             confirmText:"OK"
          });
        }
      };
      const handleOk = () => {
        createCommune()
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setData("");
        setIsModalOpen(false);
       
      };  
    useEffect(()=>{

        const seeDetailVille= async()=>{
            try {
              const response = await instance.get("detailVille/"+id);
              // console.log("detailVille",response)
              setDetailVille(response.data.data)
            } catch (error) {
              console.log(error);
             
            }
          }

          seeDetailVille()
    },[id]);
    const deleteCommune = async(idCommune)=>{
      setIsLoadingDelete(true)
      try {
        const response = await instance.delete("deleteCommune/"+idCommune);
        // console.log("deleteCommune",response)
        if(response.data.status){
          Dialog.alert({
            content: 'Sucess',
             confirmText:"OK"
          })
        }else{
          Dialog.alert({
            content: 'error',
             confirmText:"OK"
          })
        }
        setIsLoadingDelete(false)
      } catch (error) {
        console.log(error);
        Dialog.alert({
          content: error,
           confirmText:"OK"
        })
        setIsLoadingDelete(false) 
      }
    }
    const editCommune =async(idCommune)=>{
      setIsLoading(true)
      // console.log(data)
      // console.log(idCommune)
      try {
        const response = await instance.post("editCommune/"+idCommune,data);
        // console.log("detailVille",response)
        if(response.data.status){
          Dialog.alert({
            content: 'Sucess',
             confirmText:"OK"
          })
        }else{
          Dialog.alert({
            content: 'error',
             confirmText:"OK"
          })
        }
        setIsLoading(false)
      } catch (error) {
        console.log(error);
        Dialog.alert({
          content: error,
           confirmText:"OK"
        })
        setIsLoading(false)
       
      }
    }
  return (
    <>
    {
        detailVille && 
        <div>
            <div className='d-flex align-items-center'>
            <NavLink to="/tableau_bord">
            <ArrowBackIosNewIcon style={{ color: "black" }} />
          </NavLink>
          <h1 className='mx-2'>Détail de la ville : {detailVille.libelle}</h1>
            </div>

            <div>
    <Modal
      title="Ajouter une commune"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
    <input type="text"
    value={dataCreate.libelle}
    onChange={(e)=>{
      setDataCreate({...dataCreate,libelle:e.target.value})
    }}
    name="" id="" />
    </Modal>
    </div>
    <div className='text-end mx-3'>
    <button className="btn bg-dark text-light"
    onClick={()=>{
        setIsModalOpen(true);
    }}
    >Ajouter  {libelle !== "Abidjan" ? " un quartier":"une commune"}</button>
    </div>
           
       
        <div>
        <h5 className='m-4'>{libelle !== "Abidjan" ? "Quartiers":"Communes"}</h5>
        <h6 className='text-center'> {isLoading ? "Modification en cours...":null}</h6>
        <h6 className='text-center'>{isLoadingDelete ? "Suppression en cours...":null}</h6>
           {
               detailVille.communes.map(item=>(
                <div key={item.id} className='my-3 d-flex justify-content-between  align-items-center'>
                 
                   <input   id={item.id} 
                   type="text"
                    defaultValue={item.libelle}
                    onChange={
                      (e)=>{
                      setData({...data,libelle:e.target.value})
                      }
                    }
                    />
                    <div className='mx-2 d-flex gap-2'>
                    <button  onClick={()=>{
                      editCommune(item.id)
                    }}>
                     
                      Edit
                      </button>
                     <button className='bg-danger'
                     onClick={()=>{
                      deleteCommune(item.id)
                    }}
                     >X</button>
                    </div>
                   
                   </div>
               ))
           }
           {
            !detailVille.communes.length && 
            <h5 className='text-center'>Pas de {libelle !== "Abidjan" ? "Quartiers":"Communes"}</h5> 
           }
          
        </div>
       </div>
    }
   </>
  )
}

export default DetailCommune