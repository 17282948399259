import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import Detail from "../pages/Detail";
import Connexion from "../pages/Connexion";
import Dashboard from "../pages/Dashboard";
import { RequireAuth } from "../feature/RequireAuth";
import DetailDashboard from "../pages/DetailDashboard";
import DetailCommune from "../pages/features/Admin/feature/DetailCommune"
import DetailUser from "../pages/features/Admin/feature/DetailUser"
import DetailImage from "../pages/features/Admin/feature/DetailImage"
const router = createBrowserRouter([ 
    {
      path: "/",
      element: <Home />,
    },
    {
        path: "/detail/residence/:id",
        element: (
          <Detail />
        ),
      },
      {
        path: "/detail/ville/:id/:libelle",
        element: (
          <RequireAuth>
            {" "}
            <DetailCommune />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/detail/utilisateur/:id",
        element: (
          <RequireAuth>
            {" "}
            <DetailUser />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/detail/bien/image/:id/annonce/:idAnnonce",
        element: (
          <RequireAuth>
            {" "}
            <DetailImage />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/connexion",
        element: <Connexion />,
      },
      {
        path: "/tableau_bord",
        element: (
          <RequireAuth>
            {" "}
            <Dashboard />{" "}
          </RequireAuth>
        ), 
      },
      {
        path: "/detail/propriete/:id",
        element: (
          <RequireAuth>
            {" "}
            <DetailDashboard />{" "}
          </RequireAuth>
        ),   
      }
  
      ]);
  
  export default router;
