import React,{ useContext, useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import instance from "../api/api";


import PageProprietaire from './features/PageProprietaire';
import PageAdmin from './features/Admin/PageAdmin';

//  css: import "slick-carousel/slick/slick.css";
//  import "slick-carousel/slick/slick-theme.css";

// import React from "react";
// import Slider from "react-slick";

// export default function SimpleSlider() {
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };
//   return (
//     <Slider {...settings}>
//       <div>
//         <h3>1</h3>
//       </div>
//       <div>
//         <h3>2</h3>
//       </div>
//       <div>
//         <h3>3</h3>
//       </div>
//       <div>
//         <h3>4</h3>
//       </div>
//       <div>
//         <h3>5</h3>
//       </div>
//       <div>
//         <h3>6</h3>
//       </div>
//     </Slider>
//   );
// }


function Dashboard() {
  const { user } = useContext(AuthContext);
 

  const [propriete, setPropriete] = React.useState([]);
  const [detailUser, setDetailUser] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [ville, setVille] = React.useState([]);
  const [commune, setCommune] = React.useState([]);
 
  


  const getVille = async () => {
    try {
      const response = await instance.get("allVille");
      //console.log(response);
      if (response.data.status) {
        setVille(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCommune = async () => {
    try {
      const response = await instance.get("allCommune");
      //console.log(response);
      if (response.data.status) {
        setCommune(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPropriete = async () => {
    try {
      const response = await instance.get("getAnnonceUser");
     // console.log("getPropriete",response);
      if (response.data.status) {
        setPropriete(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDetailInfoUser = async () => {
    try {
      setIsLoading(true);
      const response = await instance.get("getDetailUser");
      //console.log(response);
      if (response.data.status) {
        setDetailUser(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCommune();
    getVille();
    getPropriete();
    getDetailInfoUser();
  }, []);
  return (
 
    
      <div>
    
      <section className="d-flex align-items-center detail_conteneur">
        <div className="btn btn-dash m-2">
          <NavLink to="/">
            <ArrowBackIosNewIcon style={{ color: "black" }} />
          </NavLink>
        </div>
        <h5 className="mx-2 mt-2">Tableau de bord</h5>
      </section>
      <div className="mx-2 bienvenue">
        <h1>Bienvenue</h1>
      </div>
      
      {!isLoading ? (
        <>
          <h5 className="mx-2 fw-bold">
            {user.prenoms} {user.nom}
          </h5>
          {
      user.role.libelle === "Proprietaire" && 
      < PageProprietaire
       ville={ville}
      detailUser={detailUser}
       propriete={propriete}
       commune={commune} 
       setPropriete={setPropriete} 
       getPropriete={getPropriete}
       />
        
          }
           {
      user.role.libelle === "Admin" && 
      < PageAdmin
       ville={ville}
      detailUser={detailUser}
       propriete={propriete}
       commune={commune} 
       setPropriete={setPropriete} 
       />
        
          }
        </>
      ) : (
        <div className="text-center">{isLoading ? "Chargement..." : null}</div>
      )}
    </div>
    
  );
}

export default Dashboard;
