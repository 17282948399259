import React from "react";

import { Tabs } from "antd-mobile";
import Categorie from "./feature/Categorie"
import Commune from "./feature/Commune"
import User from "./feature/User"
import AccueilStatistiques from "./feature/AccueilStatistiques"

function PageAdmin() {
  return (
    <div>
      <Tabs defaultActiveKey="1">
      <Tabs.Tab title="Home" key="1">
         <AccueilStatistiques />
        </Tabs.Tab>
        <Tabs.Tab title="Categorie" key="2">
         <Categorie />
        </Tabs.Tab>
        <Tabs.Tab title="Villes" key="3">
        <Commune />

        </Tabs.Tab>
        <Tabs.Tab title="Role" key="4"></Tabs.Tab>
        <Tabs.Tab title="Utilisateurs" key="5">
        <User />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}

export default PageAdmin;
