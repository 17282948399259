import React from "react";
import instance from "../../api/api";
import { useNavigate } from "react-router-dom";

function Profil(props) {
  const { detailUser } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    nom: "",
    prenoms: "",
    email: "",
    phone: "",
    password: "",
  });

  const updateInfo = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await instance.post("createUser", data);
      //console.log(response.data);
      if (response.data.status) setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={updateInfo}>
      <input
        type="text"
        defaultValue={detailUser.nom}
        placeholder="Nom"
        className="my-2"
        onChange={(e) => {
          setData({ ...data, nom: e.target.value });
        }}
        disabled
      />
      <input
        type="text"
        defaultValue={detailUser.prenoms}
        placeholder="Prénoms"
        className="my-2"
        onChange={(e) => {
          setData({ ...data, prenoms: e.target.value });
        }}
        disabled
      />
      <input
        type="email"
        defaultValue={detailUser.email}
        placeholder="Email"
        className="my-2"
        onChange={(e) => {
          setData({ ...data, email: e.target.value });
        }}
        disabled
      />
      <input
        type="text"
        defaultValue={detailUser.phone}
        placeholder="Contact"
        className="my-2"
        onChange={(e) => {
          setData({ ...data, phone: e.target.value });
        }}
        disabled={detailUser.phone}
      />
      <input
        type="password"
        value={data.password}
        placeholder="Nouveau mot de passe"
        className="my-2"
        onChange={(e) => {
          setData({ ...data, password: e.target.value });
        }}
        autoFocus
      />

      <div className="d-flex justify-content-center align-items-center my-3">
        <button className="btn-lg bg-dark text-light p-3 rounded-4 border-0 mx-2">
          {isLoading ? "..." : "Enregistrer"}
        </button>
        <button
          className="btn-lg bg-secondary text-light py-3 px-4 rounded-4 border-0 mx-2"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Retour
        </button>
      </div>
    </form>
  );
}

export default Profil;
