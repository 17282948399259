import React ,{ useEffect,useState,useRef}from 'react'
import instance from "../../../../api/api";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Modal } from "antd";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {TabView,TabPanel} from "primereact/tabview";
import { Dialog } from "antd-mobile";
import { NavLink } from "react-router-dom";

function Commune() {
    const [ville,setVille]=useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState({
        libelle:""
    });
    
 
  
    
      const handleOk = () => {
        createVille()
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setData("");
        setIsModalOpen(false);
       
      };  

      const createVille = async () => {
        try {
          const response = await instance.post("saveVille",data);
          // console.log("createVille",response);
          if (response.data.status) {
            Dialog.alert({
                content:"success" ,
                 confirmText:"OK"
              });
          }
        } catch (error) {
          console.log(error);
        }
      };
     
    useEffect(() => {
        const getVille = async () => {
          try {
            const response = await instance.get("allVille");
            // console.log("allVille",response);
            if (response.data.status) {
                setVille(response.data.data);
            }
          } catch (error) {
            console.log(error);
          }
        };
    
        getVille();
      }, []);
  return (
    <div className='text-end'>
    <div>
    <Modal
      title="Ajouter une ville"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
    <input type="text"
    value={data.libelle}
    onChange={(e)=>{
        setData({...data,libelle:e.target.value})
    }}
    name="" id="" />
    </Modal>
    </div>
    <button class="btn bg-dark text-light"
    onClick={()=>{
        setIsModalOpen(true);
    }}
    >Ajouter une ville</button>
    <VilleDatatable items={ville} />
</div>
   
  )
}
function VilleDatatable({ items }) {
    const dt = useRef(null);
  
    const footer = `${items ? items.length : 0} ville`;
    const supprimerCategorie = async (idCat) => {
    
        try {
          const response = await instance.delete("deleteCategorie/"+idCat);
          // console.log("deleteCategorie",response);
          if (response.data.status) {
            Dialog.alert({
                content:response.data.message ,
                 confirmText:"OK"
              });
           
          }
        } catch (error) {
          console.log(error);
        }
      };
      
    const Action = (rowData) => {
      return (
        <button 
        onClick={()=>{
          supprimerCategorie(rowData.id)
        }}
        className='btn bg-danger text-light'>
            Supprimer
            </button>
      );
    };
    const seeDetail = (rowData)=>{
        return (
            <NavLink to={"/detail/ville/" + rowData.id+"/"+rowData.libelle} >
              Voir plus
            </NavLink>
           
          );
        };
    
  
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
  
      _filters["global"].value = value;
  
      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      const conteneurSearch = {
        padding: "1.3em",
      };

      return (
        <div className="conteneur-search">
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={conteneurSearch}
          />
        </div>
      );
    };
    const header = renderHeader();
  
    return (
      <div>
        
          <TabView>
            <TabPanel>
              <DataTable
                ref={dt}
                value={items}
                footer={footer}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={5}
                filters={filters}
                globalFilterFields={["libelle"]}
                header={header}
              >
                <Column
                  field="libelle"
                  style={{ textAlign: "center" }}
                  header="libellé"
                ></Column>
                {/* <Column
                  field="assistant"
                  style={{ textAlign: "center" }}
                  header="Agence"
                  body={getNameAgence}
                ></Column> */}
                {/* <Column
                  field="status"
                  style={{ textAlign: "center" }}
                  header="Statut"
                  body={getStatut}
                ></Column> */}
  
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Action"
                  body={Action}
                ></Column>
                 <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Détail"
                  body={seeDetail}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
      </div>
    );
  }


export default Commune