import { useState, createContext, useMemo } from "react";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || ""
  );
  const [token,setToken] = useState(JSON.parse(localStorage.getItem("token")) || "")

  const values = useMemo(() => {
    return {
      user,
      token,
      login: (user,token) => {
        setUser(user);
        setToken(token);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(token));
      },
      logOut: () => {
        localStorage.removeItem("user"); 
        localStorage.removeItem("token");
        setUser("");
        setToken(" ");
      },
    };
  }, [user,token]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};