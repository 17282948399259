import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { NavLink, useParams } from "react-router-dom";
import instance from "../api/api";
import { diffForHumans } from "../utils/utils";
// import PlaceIcon from "@mui/icons-material/Place";
import { lienImage } from "../api/api";
import { Help } from "../utils/utils";
import ModalGalerrie from "./features/ModalGalerrie";
const contentStyle = {
  margin: 0,
  height: "350px",
  color: "#fff",
  textAlign: "center",
  background: "#364d79",
  width: "100%",
  objectFit: "cover",
};

function Detail() {
  const { id } = useParams();
  const [detailPropriete, setDetailPropriete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherResidence, setOtherResidence] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  // const onChange = (currentSlide) => {
  //   console.log(currentSlide);
  // };
  const seeResidence = async (id) => {
    try {
      await instance.post("addOneOnVue/" + id);
      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getDetailPropriete = async () => {
      setIsLoading(true);
      try {
        const response = await instance.get("getDetailAnnonce/" + id);
        const responseOther = await instance.get("allAnnonces");

        // console.log("DETAILANNONCE", response.data.data);
        // console.log(
        //   "ALL ANONCE",
        //   responseOther.data.data.filter(
        //     (item) => item.user_id === 3 && item.id !== id
        //   )
        // );
        if (response.data.status) {
          setDetailPropriete(response.data.data);
          setOtherResidence(
            responseOther.data.data.filter((item) => {
              return (
                item.user_id === response.data.data.user_id &&
                item.id !== Number(id)
              );
            })
          );
          // console.log(
          //   "RESPONSEOTHER",
          //   responseOther.data.data.filter((item) => {
          //     return (
          //       item.user_id === response.data.data.user_id &&
          //       item.id !== Number(id)
          //     );
          //   })
          // );
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getDetailPropriete();
  }, [id]);
  return (
    <div>
      <div className="header_detail">
        <div className="back_accueil">
          <NavLink to="/">
            <ArrowBackIosNewIcon
              style={{
                color: "black",
              }}
            />
          </NavLink>
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            zIndex: "99",
            placeItems: "center",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "#000000b5",
            color: "white",
          }}
          className="position-absolute d-flex justify-content-center
         "
        >
          Chargement....
        </div>
      )}
      {detailPropriete ? (
        <div>
          <ModalGalerrie
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            detailProprieteImages={detailPropriete.images}
          />
          <section className="container p-2 conteneur_detail_section">
            <Carousel
              autoplay
              draggable
              // afterChange={onChange}
              className="conteneur_img_detail"
            >
              {detailPropriete.images.map((item) => {
                return (
                  <div key={item.id}>
                    <img
                      className="content_style"
                      src={lienImage + item.path}
                      alt={item.path}
                    />
                  </div>
                );
              })}
            </Carousel>
            <div className="see-all-image text-end">
              <button
                className="btn bg-dark text-light my-1"
                onClick={() => showModal()}
              >
                Voir toutes les images
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center py-4 flex-wrap">
              <div>
                <span>Fournisseur :</span>
                <span className="fw-bold mx-2">
                  {detailPropriete.user.nom} {detailPropriete.user.prenoms}
                </span>
              </div>
              <span>
                Publiée il y a {diffForHumans(detailPropriete.created_at)}{" "}
              </span>
            </div>
            <span
              className="badge"
              style={{
                background:
                  detailPropriete.activation === 1 ? "green" : "crimson",
              }}
            >
              {detailPropriete.activation === 1 ? "disponible" : "indisponible"}
            </span>
            <h1>{detailPropriete.titre}</h1>
            <p className="text-secondary">
              {detailPropriete.commune.ville.libelle},
              {detailPropriete.lieu_precis}
            </p>
            <p>{detailPropriete.piece} pièces</p>
            <p>{Help.getPrice(detailPropriete.prix)} Fcfa</p>
            <hr />
            <h5>Description : </h5>
            <p>{detailPropriete.description}</p>
            <hr />
            <h5>Contacts : </h5>
            <div className="mobile_foot_bar">
              <button className="btn-lg bg-secondary p-2 m-2 fw-bold">
                <a href={"tel:2250545749741"} className="lien-call">
                  0545749741
                </a>
              </button>
              <button className="btn-lg bg-success p-2 m-2 fw-bold">
                <a
                  href={"https://wa.me/+2250545749741?text=Salut%20"}
                  className="lien-call"
                >
                  Whatsapp
                </a>
              </button>
              <button className="btn-lg bg-warning p-2 m-2 fw-bold">
                <a
                  href={"mailto:kadjopierreemmanuel30@gmail.com"}
                  className="lien-call"
                >
                  Email
                </a>
              </button>
            </div>
            <hr />
            <h5>Autre résidences du fournisseur:</h5>

            <section className="container p-2 mt-2">
              <div className="row px-2 gap-3">
                {otherResidence.length > 0 ? (
                  otherResidence.map((item) => (
                    <div
                      className="col-lg-4 conteneur_image_ecriteau"
                      key={item.id}
                    >
                      <div className="conteneur_image">
                        <Carousel
                        // afterChange={onChange}
                        >
                          {item.images.map((img) => (
                            <div key={img.id}>
                              <img
                                src={lienImage + img.path}
                                alt={img.path}
                                style={contentStyle}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                      <div className="conteneur_ecriteau d-flex justify-content-between align-items-center p-2">
                        <div>
                          <p>
                            {item.piece} Pièce{item.piece > 1 ? "s" : ""}
                          </p>
                          <span>{Help.getPrice(item.prix)} Fcfa / jour</span>
                          {/* <p className="mt-1">
                            <PlaceIcon style={{ fontSize: "1em" }} />
                            {item.commune.ville.libelle},{item.commune.libelle}
                          </p> */}
                        </div>
                        <div>
                          <NavLink
                            to={"/detail/residence/" + item.id}
                            onClick={() => {
                              seeResidence(item.id);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            className="see_prop"
                          >
                            Voir plus
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center fw-bold">
                    {isLoading ? "Chargement..." : "Pas d'autre résidence"}
                  </div>
                )}
              </div>
            </section>
          </section>
        </div>
      ) : (
        <div className="text-center">
          {isLoading ? "Chargement..." : "Pas d info"}
        </div>
      )}
    </div>
  );
}

export default Detail;
