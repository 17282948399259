import React,{useState,useEffect} from 'react'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams} from "react-router-dom";
import instance,{lienImage} from "../../../../api/api";
function DetailImage() {
    const {id,idAnnonce}=useParams();
    
    const [detailUser,setDetailUser]=useState(null);
    const [detailImage,setDetailImage]=useState(null);
    
    useEffect(()=>{
 
        const seeDetailVille= async()=>{
            try {
              const response = await instance.get("detailUser/"+id);
              // console.log("getDetailUser",response.data.data)
              setDetailUser(response.data.data)

      setDetailImage(response.data.data.annonces.find(item=>item.id === Number(idAnnonce)))
            } catch (error) {
              console.log(error);
             
            }
          }

          seeDetailVille()
    },[id,idAnnonce]);

// console.log("detailImage",detailImage)
  
  return (
    <div>
         <div className='d-flex align-items-center'>
            <button onClick={()=>{
                 window.history.back();
            }}>
                    <ArrowBackIosNewIcon 
            
            style={{ color: "black" }} />
                </button>  
        
          
          <h1 className='mx-2'>Galeries</h1>
            </div> 
        <div>
            {
                (detailImage && detailUser) && 
                detailImage.images.map(item=>(
                    <img className='image-detail'
                     src={lienImage + item.path}
                    alt={item.path} />
                ))
            }
        </div>
    </div>
  )
}

export default DetailImage