import React ,{ useEffect,useState,useRef}from 'react'
import instance from "../../../../api/api";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Modal } from "antd";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {TabView,TabPanel} from "primereact/tabview";
import { Dialog } from "antd-mobile";

function Categorie() {
    const [categorie,setCategorie]=useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState({
        libelle:""
    });  
      const handleOk = () => {
        createCategorie()
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setData("");
        setIsModalOpen(false);
       
      }; 

      const createCategorie = async () => {
        try {
          const response = await instance.post("createCategorie",data);
          // console.log("createCategorie",response);
          if (response.data.status) {
           
           setCategorie([...categorie,response.data.data])
            Dialog.alert({
                content:"success" ,
                 confirmText:"OK"
              });
            
          }
        } catch (error) {
          console.log(error);
        }
      };
     
    useEffect(() => {
        const getCategorie = async () => {
          try {
            const response = await instance.get("allCategorie");
            // console.log("allCategorie",response);
            if (response.data.status) {
                setCategorie(response.data.data);
            }
          } catch (error) {
            console.log(error);
          }
        };
    
        getCategorie();
      }, []);
      
  return (
    <div className='text-end'>
        <div>
        <Modal
          title="Ajouter une catégorie"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText={"Annuler"}
          okText={"Confirmer"}
        >
        <input type="text"
        value={data.libelle}
        onChange={(e)=>{
            setData({...data,libelle:e.target.value})
        }}
        name="text" id="text" />
        </Modal>
        </div>
        <button class="btn bg-dark text-light"
        onClick={()=>{
            setIsModalOpen(true);
        }}
        >Ajouter une catégorie</button>
        <CategorieDatatable items={categorie} setCategorie={setCategorie}/>

    </div>
  )
}

 function CategorieDatatable({ items,setCategorie }) {
    const dt = useRef(null);
  
    const footer = `${items ? items.length : 0} categories`;
    const supprimerCategorie = async (idCat) => {
    
        try {
          const response = await instance.delete("deleteCategorie/"+idCat);
          // console.log("deleteCategorie",response);
          if (response.data.status) {
            setCategorie(items.filter((item) => item.id !== idCat));
            Dialog.alert({
                content:response.data.message ,
                 confirmText:"OK"
              });
           
          }
        } catch (error) {
          console.log(error);
        }
      };
      
    const Action = (rowData) => {
      return (
        <button 
        onClick={()=>{
          supprimerCategorie(rowData.id)
        }}
        className='btn bg-danger text-light'>
            Supprimer
            </button>
      );
    };
    
  
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
  
      _filters["global"].value = value;
  
      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      const conteneurSearch = {
        padding: "1.3em",
      };

      return (
        <div className="conteneur-search">
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={conteneurSearch}
          />
        </div>
      );
    };
    const header = renderHeader();
  
    return (
      <div>
        
          <TabView>
            <TabPanel>
              <DataTable
                ref={dt}
                value={items}
                footer={footer}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={5}
                filters={filters}
                globalFilterFields={["libelle"]}
                header={header}
              >
                <Column
                  field="libelle"
                  style={{ textAlign: "center" }}
                  header="libellé"
                ></Column>
  
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Détail"
                  body={Action}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
      </div>
    );
  }

export default Categorie