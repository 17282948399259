import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Modal } from "antd";
import { NavLink } from "react-router-dom";
import instance from "../api/api";
import { lienImage } from "../api/api";
import { Help } from "../utils/utils";
import { Dialog } from "antd-mobile";

function Propriete({ propriete }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [annonceId, setAnnonceId] = React.useState(null);
  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const showModal = () => {
    setIsModalOpen(true);
    setAnchorEl(null);
  };
  const handleOk = () => {
    deletePropriete();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const desactivePropriete = async (propId, propActivation) => {
    let dataForm = {
      activation: propActivation === 2 ? 1 : 2,
    };
    try {
      const response = await instance.post("updateAnnonce/" + propId, dataForm);
      //console.log(response.data);
      if (response.data.status) {
        propriete.activation = dataForm.activation;
        const msg = propActivation === 1 ? "désactivé" : "activé";
        Dialog.alert({
          content: `Le bien est ${msg}`,
          confirmText:"OK"
        });
        setAnchorEl(null)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePropriete = async () => {
    try {
      await instance.delete("deleteAnnonce/" + annonceId);
      //console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="residence-conteneur-mobile d-flex justify-content-between align-items-center py-2 px-4 ">
      <div className="d-flex align-items-center">
        {propriete.images[0] ? (
          <img
            className="imagePropriete"
            src={lienImage + propriete.images[0].path}
            alt="residence"
          />
        ) : (
          "pas d'image"
        )}

        <div className="mx-2">
          {propriete.activation === 1 ? (
            <h6 className="badge bg-success">Pas occupée</h6>
          ) : (
            <h6 className="badge bg-danger">Occupée</h6>
          )}

          <h5>{propriete.titre}</h5>
          <h6>{Help.getPrice(propriete.prix)} Fcfa</h6>
          <h6>{propriete.commune.libelle}</h6>

          <h6>Vue : {propriete.vue}</h6>
        </div>
      </div>

      <div>
        <Modal
          title="Suppression"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText={"Annuler"}
        okText={"Confirmer"}
        >
          <p>Voulez-vous supprimer la propriété ? </p>
        </Modal>
        <MoreVertIcon onClick={handleMenu} />
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <NavLink
              to={"/detail/propriete/" + propriete.id}
              className="see_prop"
            >
              Voir plus
            </NavLink>
          </MenuItem>
          <MenuItem
            onClick={() => {
              desactivePropriete(propriete.id, propriete.activation);
            }}
          >
            {propriete.activation === 2 ? 'Activée':'Désactivée'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnnonceId(propriete.id);
              showModal();
            }}
          >
            Supprimer
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default Propriete;
