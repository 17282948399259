import { useState, createContext, useMemo } from "react";
export const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
    const [ showSearch,setShowSearch] = useState(false)

  
    const values = useMemo(() => {
      return {
        showSearch,
       
        ToggleSearch: () => {
       
            setShowSearch(search=>!search)
        },
      };
    }, [showSearch]);
  
    return <SearchContext.Provider value={values}>{children}</SearchContext.Provider>;
  };