import React from 'react'
// import instance from "../../../../api/api";

function AccueilStatistiques() {
    // const [user,setUser]=useState(0);
    // useEffect(()=>{
    //     const getUser = async () => {
    //         try {
    //           const response = await instance.get("getUserOnline");
    //           console.log("getUserOnline",response); 
    //           if (response.data.status) {
    //             setUser(response.data.data.length);
    //           }
    //         } catch (error) {
    //           console.log(error);
    //         }
    //       };
      
    //       getUser();

    // },[])
  return (
    <div>
        <div>
            <h2>Date: {new Date().toLocaleDateString("fr")}</h2>
            
            {/* Nombre de personnes connectées  :{user} */}
        </div>
    </div>
  )
}

export default AccueilStatistiques