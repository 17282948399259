import React from 'react'
import { Modal } from 'antd';
import { lienImage } from "../../api/api";

function ModalGalerrie({isModalOpen,setIsModalOpen,detailProprieteImages}) {
 const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal title="Galerie"
    footer={false}
    style={{
      top: 10
    }}open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {detailProprieteImages.map((item) => {
                return (
                  <div key={item.id} className='my-3'>
                    <img
                      className="content_style"
                      src={lienImage + item.path}
                      alt={item.path}
                    />
                  </div>
                );
              })}
      </Modal>
  )
}

export default ModalGalerrie
