import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import instance from "../api/api";
import { CiHome } from "react-icons/ci";

function Header() {
  const { user, logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const seDeconnecter = async () => {
    try {
      await instance.post("logout");
      logOut();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <div className="d-flex justify-content-between align-items-center p-2 conteneur-header">
      <h1 className="mx-1 d-flex align-items-center">
         <CiHome /></h1>
      <div className="mx-4 d-flex align-items-center">
        {user.prenoms ? (
          <div className="d-flex align-items-center">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <span className="mx-1">{user.prenoms}</span>{" "}
              <Avatar alt={user.prenoms} src="/static/images/avatar/1.jpg" />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate("/tableau_bord")}>
                Tableau de bord
              </MenuItem>
              <MenuItem
                onClick={() => {
                  seDeconnecter();
                }}
              >
                {" "}
                Déconnexion
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <div>
            <NavLink to="/connexion" className="see_prop fw-bold">
              Connexion
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
