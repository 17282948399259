import React ,{ useContext,useState}from 'react'
import { Tabs } from "antd-mobile";
import instance,{ lienImage } from "../../api/api";
import { NavLink } from "react-router-dom";
import Profil from "../../feature/dashboard/Profil";
import Propriete from "../../feature/Propriete";
import { AuthContext } from "../../context/AuthContext";
import { Dialog } from "antd-mobile";
import { Modal } from "antd";


const ListPropriete =({propriete,showModal,showModalDesactive,setAnnonceId,setNbreActivation})=>{
return(
  <table className="table table-striped desktop-version">
  <thead>
    <tr>
      <th>Image</th>
      <th>Libellé</th>
      <th>Prix (Fcfa)</th>
      <th>Commune</th>
      <th>Statut</th>

      <th>Vue</th>
      <th>Détail</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {propriete.map((item) => {
      return (
        <tr key={item.id}>
          <td>
            {item.images[0] ? (
              <img
                className="imagePropriete"
                src={
                  lienImage + item.images[0].path
                }
                alt="residence"
              />
            ) : (
              "Pas d'image"
            )}
          </td>
          <td className="text-center">{item.titre}</td>
          <td>{item.prix}</td>
          <td>{item.commune.libelle}</td>
          <td>
            {item.activation === 1 ? (
              <span className="badge bg-success">
                Disponible
              </span>
            ) : (
              <span className="badge bg-danger">Occupée</span>
            )}
          </td>

          <td>{item.vue}</td>
          <td>
            <NavLink to={"/detail/propriete/" + item.id}>
              Voir
            </NavLink>
          </td>
          <td>
            <div className="d-flex align-items-center gap-2 justify-content-center">
              <button
                onClick={() => {
                  setAnnonceId(item.id);
                  setNbreActivation(item.activation);
                  showModalDesactive(item.id);
                }}
              >
                {item.activation === 2 ? "Activer" : "Désactiver"}
              </button>
              <button
                onClick={() => {
                  setAnnonceId(item.id);
                  showModal(item.id);
                }}
              >
                Supprimer
              </button>
            </div>
          </td>
        </tr>
      );
    })}
    {!propriete.length && (
      <tr>
        <td colSpan={8}>Pas de données</td>
      </tr>
    )}
  </tbody>
</table>
);
}


function PageProprietaire({propriete,detailUser,commune,ville,setPropriete,getPropriete}) {
    const { user } = useContext(AuthContext);
    const [images, setImages] = useState([]);
    const handleFileChange = (event) => {
      const files = Array.from(event.target.files);
      setImages(files);
  };
    const [dataAnnonce, setDataAnnonce] = React.useState({
        activation: 1,
        titre: "",
        lieu_precis: "",
        description: "",
        coordonnees: "",
        prix: "",
        premium: 0,
        commune_id: "",
        user_id: user.id,
        categorie_id: 4,
        piece: "",
      });
      const [isLoading, setIsLoading] = React.useState(false);
    const [annonceId, setAnnonceId] = React.useState(null);
    const [annonceName, setAnnonceName] = React.useState(null);
    const [nbreActivation, setNbreActivation] = React.useState(null);
    const [isModalOpenDesactive, setIsModalOpenDesactive] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [filterCommune, setFilterCommune] = React.useState([]);
    const handleOk = () => {
        deletePropriete();
        setIsModalOpen(false);
      };
      const handleOkDesactive = () => {
        setIsModalOpenDesactive(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleCancelDesactive = () => {
        setIsModalOpenDesactive(false);
      };
      const recupereName = (idProp)=>{
        const {titre} =  propriete.find(item=>item.id === idProp)
        setAnnonceName(titre)
      }
    const showModal = (id) => {
        recupereName(id)
        setIsModalOpen(true);
      };
    const showModalDesactive = (id) => {
      recupereName(id)
        setIsModalOpenDesactive(true);
      };
      const submitAnnonce = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let dataForm = new FormData();
        dataForm.append("activation", dataAnnonce.activation);
        dataForm.append("titre", dataAnnonce.titre);
        dataForm.append("lieu_precis", dataAnnonce.lieu_precis);
        dataForm.append("description", dataAnnonce.description);
        dataForm.append("coordonnees", dataAnnonce.coordonnees);
        dataForm.append("premium", dataAnnonce.premium);
        dataForm.append("prix", dataAnnonce.prix);
        dataForm.append("commune_id", dataAnnonce.commune_id);
        dataForm.append("user_id", dataAnnonce.user_id);
        dataForm.append("categorie_id", dataAnnonce.categorie_id);
        dataForm.append("piece", dataAnnonce.piece);

        for (const item of images) {
          dataForm.append("path[]", item);
        }
        try {
          const response = await instance.post("saveAnnonce", dataForm);
          // console.log("SAVEANNONCE",response.data);
          if (response.data.status) {
            setIsLoading(false);
            alert("Bien enregistré");
            resetData();
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
      const resetData = () => {
        dataAnnonce.titre = ""
        dataAnnonce.lieu_precis = ""
        dataAnnonce.description = ""
        dataAnnonce.coordonnees = ""
        dataAnnonce.prix = ""
        dataAnnonce.commune_id = ""
        dataAnnonce.piece = ""
      };
      const desactivePropriete = async () => {
        let dataForm = {
          activation: nbreActivation === 2 ? 1 : 2,
        };
        // console.log("annonceId", annonceId);
        try {
          const response = await instance.post(
            "updateAnnonce/" + annonceId,
            dataForm
          );
          //console.log(response.data);
          if (response.data.status) {
            getPropriete()
            const msg = nbreActivation === 1 ? "Désactivé" : "Activé";
            setIsLoading(false);
            Dialog.alert({
              content: `Le bien est ${msg}`,
              confirmText:"OK"
            });
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
      const deletePropriete = async () => {
        try {
          const response = await instance.delete("deleteAnnonce/" + annonceId);
          //console.log(response.data);
          if (response.data.status) {
            Dialog.alert({
              content: "Le bien est supprimé",
               confirmText:"OK"
            });
            setPropriete(propriete.filter((item) => item.id !== annonceId));
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
      
  return (
    <div>
      <Modal
        title="Suppression"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={"Annuler"}
        okText={"Confirmer"}
      >
        <p>Voulez-vous supprimer la propriété  
          <span className='text-danger'>{`'${annonceName}'`}</span>? </p>
      </Modal>
      <Modal
        title={nbreActivation === 1 ? "Désactiver" : "Activer"}
        open={isModalOpenDesactive}
        onOk={() => {
          desactivePropriete();
          handleOkDesactive();
        }}
        onCancel={handleCancelDesactive}
        cancelText={"Annuler"}
        okText={"Confirmer"}
      >
        <p>
          Voulez-vous {nbreActivation === 1 ? "désactiver" : "activer"} la
          propriété <span className='text-danger'>{`'${annonceName}'`}</span> ?{" "}
        </p>
      </Modal>
        {
             isLoading && <div className="text-center">
                {isLoading ? "Chargement..." : null}
                </div>
        }
          <Tabs defaultActiveKey="1">
            <Tabs.Tab title="Profil" key="1">
              <Profil detailUser={detailUser} />
            </Tabs.Tab>
            <Tabs.Tab title="Propriétés" key="2">
            {propriete.length > 0 && (
                <h6 className="m-4">{propriete.length} Propriétés</h6>
              )}
            <ListPropriete
            propriete={propriete}
            showModal={showModal}
            showModalDesactive={showModalDesactive}
            setAnnonceId={setAnnonceId}
            setNbreActivation={setNbreActivation}
            />   
            <section className="mobile-section">
                {!propriete.length && (
                  <div className="text-center fw-bold">Pas de propriété</div>
                )}
                {propriete.map((residence) => {
                  return <Propriete key={residence.id} propriete={residence} />;
                })}
              </section>
            </Tabs.Tab>
            <Tabs.Tab title="Enregistrement de propriété" key="3">
              <form onSubmit={submitAnnonce}>
                <label htmlFor="libelle">Libellé (Nom de la propriété)</label>
                <input
                  type="text"
                  id="libelle"
                  className="my-2"
                  onChange={(e) => {
                    setDataAnnonce({ ...dataAnnonce, titre: e.target.value });
                  }}
                />
                <label htmlFor="ville">Ville</label>
                <select
                  name="ville"
                  className="selected"
                  id="ville"
                  onChange={(e) => {
                    setDataAnnonce({ ...dataAnnonce, ville: e.target.value });
                    setFilterCommune(
                      commune.filter(
                        (item) => item.ville_id === Number(e.target.value)
                      )
                    );
                  }}
                  defaultValue={dataAnnonce.ville}
                >
                  <option value="" disabled>
                    Sélectionne la ville
                  </option>
                  {ville.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    );
                  })}
                </select>

                 <label htmlFor="commune">Quartiers</label>
                <select
                  name="commune"
                  id="commune"
                  className="selected"
                  onChange={(e) => {
                    setDataAnnonce({
                      ...dataAnnonce,
                      commune_id: e.target.value,
                    });
                  }}
                  defaultValue={dataAnnonce.commune_id}
                > 
                  <option value="" disabled>
                    Sélectionne le quartier
                  </option>
                  {filterCommune.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="quartier">Précisement</label>
                <input
                  type="text"
                  id="quartier"
                  className="my-2"
                  onChange={(e) => {
                    setDataAnnonce({
                      ...dataAnnonce,
                      lieu_precis: e.target.value,
                    });
                  }}
                />

                <label htmlFor="prix">Prix</label>
                <input
                  type="number"
                  className="my-2"
                  name="prix"
                  min={1}
                  onChange={(e) => {
                    setDataAnnonce({ ...dataAnnonce, prix: e.target.value });
                  }}
                />
                <label htmlFor="prix">Nombre de pièce</label>
                <select
                  name="prix"
                  className="selected"
                  id="prix"
                  onChange={(e) => {
                    setDataAnnonce({ ...dataAnnonce, piece: e.target.value });
                  }}
                  defaultValue={dataAnnonce.piece}
                >
                  <option value="" disabled>
                    Choisir
                  </option>

                  <option value="1">Studio</option>
                  <option value="2">2 pièces</option>
                  <option value="3">3 pièces</option>
                  <option value="3">4 pièces</option>
                  <option value="5">5 pièces</option>
                </select>

                <label htmlFor="desc">Description</label>
                <textarea
                  name="desc"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="w-100 p-2"
                  onChange={(e) => {
                    setDataAnnonce({
                      ...dataAnnonce,
                      description: e.target.value,
                    });
                  }}
                ></textarea>
                <label htmlFor="image">Importer des images</label>
                <div className="pb-5" id="image">
                  <input type="file" multiple onChange={handleFileChange} />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn-lg bg-dark p-3 rounded-4 border-0 mx-2 text-light">
                    {isLoading ? 'Chargement...':'Enregistrer'}
                  </button>
                </div>
              </form>
            </Tabs.Tab>
          </Tabs>
    </div>
  )
}

export default PageProprietaire