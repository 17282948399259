import React, { useState, useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import instance from "../api/api";
// import { UploadOutlined } from "@ant-design/icons";
// import { Button, Upload } from "antd";
import {lien,lienImage} from "../api/api";
import { alert } from "antd-mobile/es/components/dialog/alert";
// let tableauImage = [];
// const props = {
//   listType: "picture",
//   multiple: true,
//   onChange(e) {
//     tableauImage = [];
//     console.log("e.fileList", e.fileList);
//     e.fileList.forEach((item) => {
//       tableauImage.push(item.originFileObj);
//     });

//     console.log("tableauImage", tableauImage);
//   },
//   beforeUpload(file) {
//     return new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => {
//         const img = document.createElement("img");
//         img.src = reader.result;
//         img.onload = () => {
//           const canvas = document.createElement("canvas");
//           canvas.width = img.naturalWidth;
//           canvas.height = img.naturalHeight;
//           const ctx = canvas.getContext("2d");
//           ctx.drawImage(img, 0, 0);
//           ctx.textBaseline = "middle";
//           ctx.font = "33px Arial";
//           ctx.fillText("Ant Design", 20, 20);
//           canvas.toBlob((result) => resolve(result));
//         };
//       };
//     });
//   },
// };

function DetailDashboard() {
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const [detailPropriete, setDetailPropriete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  const { id } = useParams();
  const [dataUpdate, setDataUpdate] = useState({
    prix: "",
    piece: "",
    description: "",
    lieu_precis: "",
  });
  const [images, setImages] = useState([]);
    const handleFileChange = (event) => {
      const files = Array.from(event.target.files);
      setImages(files);
  };
  const updateAnnonce = async () => {
    setIsLoading(true);

    let dataForm = new FormData();
    dataForm.append("titre", detailPropriete.titre);
    dataForm.append(
      "lieu_precis",
      dataUpdate.lieu_precis
        ? dataUpdate.lieu_precis
        : detailPropriete.lieu_precis
    );
    dataForm.append(
      "description",
      dataUpdate.description
        ? dataUpdate.description
        : detailPropriete.description
    );

    dataForm.append(
      "prix",
      dataUpdate.prix ? dataUpdate.prix : detailPropriete.prix
    );
    dataForm.append(
      "piece",
      dataUpdate.piece ? dataUpdate.piece : detailPropriete.piece
    );
    for (const item of images) {
      dataForm.append("path[]", item);
    }


    try {
      const response = await instance.post(
        "updateAnnonce/" + detailPropriete.id,
        dataForm
      );
      //console.log(response.data);
      if (response.data.status){
        alert({
          title:"le bien a été modifié.",
           confirmText:"OK"
        })
        setIsLoading(false);
        getDetailPropriete();
        setImages([]);
      } 
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const supprimeImage = async (id) => {
    //console.log(id);
    try {
      const response = await instance.delete("deletePhoto/" + id);
      if (response.data.status) {
        getDetailPropriete();
      }
      //console.log(response.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getDetailPropriete = async () => {
    try {
      const response = await instance.get("getDetailAnnonce/" + id);
      //console.log("DETAILANNONCE", response.data.data);
      if (response.data.status) {
        setDetailPropriete(response.data.data);
       
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getDetailPropriete = async () => {
      try {
        const response = await instance.get("getDetailAnnonce/" + id);
        //console.log("DETAILANNONCE", response.data.data);
        if (response.data.status) {
          setDetailPropriete(response.data.data);
          setIsLoadingDetail(false);
        }
      } catch (error) { 
        console.log(error);
      }
    };
    getDetailPropriete();
  }, [id]);
  if(isLoadingDetail) return <h3 className="text-center">Chargement...</h3>
  return (
    <div>
      <section className="d-flex align-items-center detail_conteneur">
        <div className="btn btn-dash m-2">
          <NavLink to="/tableau_bord">
            <ArrowBackIosNewIcon style={{ color: "black" }} />
          </NavLink>
        </div>
        <h5 className="mx-2 mt-2">Détail propriété</h5>
      </section>
      
      {detailPropriete && (
        <div className="pt-5">
          <h1 className="m-2">{detailPropriete.titre}</h1>
          <form className="mx-3 mb-2">
            <label htmlFor="">Libellé</label>
            <input type="text" defaultValue={detailPropriete.titre} disabled />
            <label htmlFor="">Prix</label>
            <input
              type="text"
              defaultValue={detailPropriete.prix}
              disabled={isDisabled}
              onChange={(e) => {
                setDataUpdate({ ...dataUpdate, prix: e.target.value });
              }}
            />
            <label htmlFor="">Ville</label>
            <input
              type="text"
              disabled
              defaultValue={detailPropriete.commune.ville.libelle}
            />
            <label htmlFor="">Commune</label>
            <input
              type="text"
              disabled
              defaultValue={detailPropriete.commune.libelle}
            />
            <label htmlFor="">Nombre de pièce</label>
            <input
              type="number"
              defaultValue={detailPropriete.piece}
              disabled={isDisabled}
              onChange={(e) => {
                setDataUpdate({ ...dataUpdate, piece: e.target.value });
              }}
            />
            <label htmlFor="">Située précisement</label>
            <input
              type="text"
              defaultValue={detailPropriete.lieu_precis}
              disabled={isDisabled}
              onChange={(e) => {
                setDataUpdate({ ...dataUpdate, lieu_precis: e.target.value });
              }}
            />
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              id="description"
              cols="30"
              rows="10"
              className="w-100 p-2"
              defaultValue={detailPropriete.description}
              disabled={isDisabled}
              onChange={(e) => {
                setDataUpdate({ ...dataUpdate, description: e.target.value });
              }}
            ></textarea>
            <div className="container">
              <div className="row align-items-center gap-2">
                {detailPropriete.images.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="col-lg-2 position-relative"
                    >
                      {!isDisabled && <span
                        className="badge bg-danger position-absolute delete-img"
                        id={item.id}
                        onClick={(e) => {
                          e.preventDefault();
                          //console.log(e.target.id);
                          supprimeImage(e.target.id);
                        }}
                      >
                        x
                      </span>}
                      <img
                      
                        src={
                          lienImage + item.path
                        }
                        alt={item.path}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <label htmlFor="image">Importer des images</label>
                <div className="pb-5" id="image">
                  <input  disabled={isDisabled} type="file" multiple onChange={handleFileChange} />
                </div>
          </form>
          <div className="text-center p-2">
            {!isDisabled ? (
              <button
                className="btn bg-success mx-2 text-light fw-bold"
                onClick={updateAnnonce}
              >
                {!isLoading ? 'Confirmer':'Chargement...'}
              </button>
            ) : (
              <button
                className="btn bg-warning mx-2 text-light fw-bold"
                onClick={(e) => {
                  e.preventDefault();
                  setIsDisabled(false);
                }}
              >
                Modifier
              </button>
            )}
            <button
              className="btn bg-danger mx-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault();
                navigate("/tableau_bord");
              }}
            >
              Retour
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailDashboard;
