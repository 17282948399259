import React, { useEffect, useState,useRef} from 'react'
import { useParams,NavLink } from "react-router-dom";
import instance from "../../../../api/api";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import { Dialog } from "antd-mobile";
// import { Modal } from "antd";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Modal } from "antd";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {TabView,TabPanel} from "primereact/tabview";
import { Dialog } from "antd-mobile";

function DetailUser() {
    const {id}=useParams();
      const [detailUser,setDetailUser]=useState(null);

      useEffect(()=>{

        const seeDetailVille= async()=>{
            try {
              const response = await instance.get("detailUser/"+id);
              // console.log("getDetailUser",response)
              setDetailUser(response.data.data)
            } catch (error) {
              console.log(error);
             
            }
          }

          seeDetailVille()
    },[id]);
  return (
    <div>
        {
            detailUser && 
            <>
            <div className='d-flex align-items-center'>
            <NavLink to="/tableau_bord">
            <ArrowBackIosNewIcon style={{ color: "black" }} />
          </NavLink>
          <h1 className='mx-2'>Détail de : {detailUser.nom}</h1>
            </div>
            <div>
            <p> Enregistré le : 
                {new Date(detailUser.created_at).toLocaleDateString('fr')}</p>
                <span className='badge bg-info text-dark mt-3'>
                {detailUser.role.libelle}</span>
                <p> Nom & prénoms: 
                {detailUser.nom} {detailUser.prenoms}</p>
                <p> Email: 
                {detailUser.email}</p>
                <p> Phone: 
                {detailUser.phone}</p>
                <p> Annonces:</p>
                <AnnonceDatatable items={detailUser.annonces} />

          </div>
            </>
        }
    </div>
  )
}

function AnnonceDatatable({ items }) {
    const dt = useRef(null);
  
    const footer = `${items ? items.length : 0} annonces`;
    // const desactiver = async (idCat) => {
    
    //     try {
    //       const response = await instance.delete("deleteCategorie/"+idCat);
    //       console.log("deleteCategorie",response);
    //       if (response.data.status) {
    //         Dialog.alert({
    //             content:response.data.message ,
    //           });
           
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
      
    // const Action = (rowData) => {
    //   return (
    //     <button 
    //     onClick={()=>{
    //       desactiver(rowData.id)
    //     }}
    //     className='btn bg-dark text-light'>
    //         Désactiver
    //         </button>
    //   );
    // };
    const seePhoto = (rowData)=>{
      
      return(
        <NavLink to={"/detail/bien/image/" + rowData.user_id + "/annonce/"+ rowData.id} >
              Voir
            </NavLink>
      )
    }
    const seeDetail = (rowData)=>{
        return (
            <NavLink to={"/detail/utilisateur/" + rowData.id} >
              Voir plus
            </NavLink>
           
          );
        };

    const getDateCreate=(rowData)=>{
      return(
        <p>{new Date(rowData.created_at).toLocaleDateString("fr")}</p>
      )
    }
    const getAbonnement=(rowData)=>{
      return(
        <p>
          {rowData.premium === 0 ? "basic":"Premuim"}
        </p>
      )
    }
    
  
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
  
      _filters["global"].value = value;
  
      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      const conteneurSearch = {
        padding: "1.3em",
      };

      return (
        <div className="conteneur-search">
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={conteneurSearch}
          />
        </div>
      );
    };
    const header = renderHeader();
  
    return (
      <div>
        
          <TabView>
            <TabPanel>
              <DataTable
                ref={dt}
                value={items}
                footer={footer}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={5}
                filters={filters}
                globalFilterFields={["libelle"]}
                header={header}
              >
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Date"
                  body={getDateCreate}
                ></Column>
                <Column
                  field="titre"
                  style={{ textAlign: "center" }}
                  header="Titre"
                ></Column>
                <Column
                  field="lieu_precis"
                  style={{ textAlign: "center" }}
                  header="Lieu"
                ></Column>
                <Column
                  field="prix"
                  style={{ textAlign: "center" }}
                  header="Prix du bien"
                ></Column>
                <Column
                  field="piece"
                  style={{ textAlign: "center" }}
                  header="Nbre de piece"
                ></Column>
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Abonnement"
                  body={getAbonnement}
                ></Column>
  
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Image"
                  body={seePhoto}
                ></Column>
                 <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Détail"
                  body={seeDetail}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
      </div>
    );
  }

export default DetailUser