import React from 'react'

function Notification({status,message}) {
  return (
    <div className="text-center text-light p-2">
        <span className='text-danger'>
            {status ? "sucess":message}
        </span>
    </div>
  )
}

export default Notification